import { EditorPlatformApp, EditorSDK } from '@wix/platform-editor-sdk';
import { setNewLayoutStyleForProfileWidget, getLayoutType } from './services/styles';
import { toMonitored, initializeMonitoring } from './services/monitoring';
import { createAppAPI } from './services/app-api';

let editorSDK: EditorSDK;
export const editorScript: EditorPlatformApp = {
  editorReady: async (editorSDK_, _, options) => {
    editorSDK = editorSDK_;
    await initializeMonitoring(editorSDK, options);
    await editorSDK.editor.setAppAPI('', createAppAPI(editorSDK));

    if (!options.firstInstall) {
      return;
    }
    const isADI = options.origin.type === 'ADI';
    const isHorizontalLayout = !isADI;

    if (isHorizontalLayout) {
      await toMonitored('setHorizontalLayout', () => {
        return setNewLayoutStyleForProfileWidget(editorSDK);
      });
    }
  },
  exports: {
    isHorizontalLayout: () => getLayoutType(editorSDK)
  }
};
