import { EditorSDK } from '@wix/platform-editor-sdk';
import {
  PROFILE_CARD_APP_DEF_ID,
  PROFILE_LAYOUT_PARAM_KEY,
  PROFILE_WIDGET_HORIZONTAL_LAYOUT_STYLE_PARAM
} from '../constants/apps';

async function getCardCompRef(editorSDK: EditorSDK) {
  const { applicationId } = await editorSDK.tpa.app.getDataByAppDefId('', PROFILE_CARD_APP_DEF_ID);
  const [tpaCompRef] = await editorSDK.tpa.app.getAllCompsByApplicationId('', applicationId);
  return editorSDK.components.getById('', { id: tpaCompRef.id });
}

// In order to make sure the new layouts are coming with correct default app setting, we have to set it from editor script
// As this happens only on install, we always expect only one profile card widget to be installed
export async function setNewLayoutStyleForProfileWidget(editorSDK: EditorSDK) {
  const compRef = await getCardCompRef(editorSDK);
  return editorSDK.document.tpa.setStyleParams('', {
    compRef,
    styleParams: [PROFILE_WIDGET_HORIZONTAL_LAYOUT_STYLE_PARAM]
  });
}

export async function getLayoutType(editorSDK: EditorSDK): Promise<number> {
  const compRef = await getCardCompRef(editorSDK);
  const params = await editorSDK.document.tpa.getStyleParams('', { compRef });
  return params.numbers[PROFILE_LAYOUT_PARAM_KEY];
}
